<template>
    <Defaultlayout :userprofile="userprofile">
        <section class="basket add-wishlist" v-if="loading == false">
                <div class="container">
                    <!-- Display selected store -->
                    <div class="order-box" v-if="wishliststoredetails!=null">
                        <!-- Display a selected store -->
                        <div class="profile-id" >
                            <div class="media">
                                <div class="ordered-profile mr-3">
                                    <img
                                        :src="wishliststoredetails.storelogo"
                                        alt="..."
                                        v-if="wishliststoredetails.storelogo!='' && is_business_model!=3"
                                    >
                                    <img
                                        :src="wishliststoredetails.store_logo"
                                        alt="..."
                                        v-if="wishliststoredetails.store_logo!='' && is_business_model == 3"
                                    >
                                    <avatar
                                        :fullname="wishliststoredetails.storename.split(' ')[0]"
                                        :size="80"
                                        :radius="0" 
                                        :color="avatarBackgroundColor"
                                        v-if="wishliststoredetails.storelogo=='' && is_business_model!=3"
                                    ></avatar>
                                    <avatar
                                        :fullname="wishliststoredetails.storename.split(' ')[0]"
                                        :size="80"
                                        :radius="0" 
                                        :color="avatarBackgroundColor"
                                        v-if="wishliststoredetails.store_logo=='' && is_business_model == 3"
                                    ></avatar>
                                </div>
                                <div class="media-body">
                                    <div class="row form-row">
                                        <div class="col-sm-7 order-description">
                                            <h5 class="d-inline-block w-auto" v-if="is_business_model!=3">{{wishliststoredetails.storename}}</h5>
                                            <h5 class="d-inline-block w-auto" v-if="is_business_model==3">{{wishliststoredetails.store_name}}</h5>
                                            <a href="javascript:void(0);" @click="openStore()" v-if="is_business_model!=3" :style="textcolor" class="d-inline-block ml-3">Change</a>
                                            <h6 v-if="wishliststoredetails.categoryname!=null && is_business_model!=3">{{wishliststoredetails.categoryname}}</h6>
                                            <h6 v-if="wishliststoredetails.category_name!=null && is_business_model==3">{{wishliststoredetails.category_name}}</h6>
                                            <a class="location" v-if="wishliststoredetails.areaname && is_business_model!=3" href="javascript:void(0);">
                                                <img src="../../assets/images/location-icon-gray.png" alt="location-icon.png">
                                                <span> {{wishliststoredetails.areaname}}</span>
                                            </a>
                                            <a class="location" v-if="wishliststoredetails.area_name && is_business_model==3" href="javascript:void(0);">
                                                <img src="../../assets/images/location-icon-gray.png" alt="location-icon.png">
                                                <span> {{wishliststoredetails.area_name}}</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="backshare d-flex flex-wrap align-items-center ml-auto">
                                    <a href="javascript:void(0)" @click="goBack" class="d-flex flex-wrap align-items-center">
                                        <img src="../../assets/images/pre-slider-btn.png">
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- select a store -->
                    <div v-if="wishliststoredetails == null && is_business_model!=3" class="order-box">
                        <div class="profile-id">
                            <div class="media">
                                <div class="wishlist-add ordered-profile mr-3 d-flex flex-wrap align-items-center justify-content-center">
                                    <a href="javascript:void(0)" class="wishlistadd-icon" @click="openStore()">
                                        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                width="40px" height="40px" viewBox="0 0 9.293 9.292" enable-background="new 0 0 9.293 9.292" xml:space="preserve">
                                            <rect x="0.501" y="4.269" stroke="#000000" stroke-miterlimit="10" width="8.292" height="0.753"/>
                                            <rect x="4.27" y="0.5" stroke="#000000" stroke-miterlimit="10" width="0.753" height="8.292"/>
                                        </svg>
                                    </a>
                                </div>
                                <span>
                                    <a href="javascript:void(0)" @click="openStore()">
                                        <p style="color:black !important">Select a store</p>
                                    </a>
                                </span>
                                <div class="backshare d-flex flex-wrap align-items-center ml-auto">
                                    <a href="javascript:void(0)" @click="goBack" class="d-flex flex-wrap align-items-center">
                                        <img src="../../assets/images/pre-slider-btn.png">
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="your-basket">
                        <div class="d-sm-flex justify-content-sm-between flex-wrap">
                            <h2 class="section-heading d-inline-block">Your Wishlist ({{totalWishListQty}} items)</h2>
                            <div class="your-basket-right d-flex justify-content-between">
                                <div class="add-more-btn ml-3">
                                    <a href="javascript:void(0);" :style="colorObj" class="btn button-style hoverEffect" @click="selectStoreItem(wishliststoredetails.storeid)" :class="wishliststoredetails == null ? 'disabled':''" style="padding:7px;">Add Items</a>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="basket-order col-lg-8 mt-4 mt-lg-0" v-if="wishlist_store_item == null">
                                <div class="table-responsive basket-list">
                                    <div class="empty-box text-center">
                                    <div class="contents">
                                        <div class="empty-image">
                                        <img src="../../assets/images/svgs/empty-box.svg" alt="empty-box">
                                        </div>
                                    <h3>No Records Found</h3>
                                    </div>
                                    <a href="javascript:void(0);" :style="colorObj" class="btn button-style hoverEffect" @click="selectStoreItem(wishliststoredetails.storeid)" :class="wishliststoredetails == null ? 'disabled':''" style="padding:7px;">Add Items</a>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="wishlist-items basket-order col-lg-8 mt-4 mt-lg-0" v-if="wishlist_store_item != null">
                                <div class="table-responsive basket-list">
                                    <p class="table-title d-md-none"></p>
                                    <table class="table table-borderless" border="0">
                                        <thead>
                                            <tr>
                                                <th class="order-item-main">Items</th>
                                                <th class="unit-price-main">Unit Price</th>
                                                <th class="quantity-main">Quantity</th>
                                                <th class="close-main"></th>
                                            </tr>
                                        </thead>
                                        <tbody v-for="(wishlist_item,index) in wishlist_store_item" :key="index">
                                            <tr class="first-item" v-for="(product,ind) in getAddedWishlistItems(wishlist_item.item_variants)" :key="ind">
                                                <td class="order-item">
                                                    <div class="d-flex">
                                                        <div class="ordered-profile mr-3">
                                                            <img :src="wishlist_item.item_image" />
                                                        </div>
                                                        <div class="order-description d-flex flex-wrap align-items-center">
                                                            <h5 class="w-100 mb-2" v-if="wishlist_item.category_name!=null && wishlist_item.sub_category_name!=null">{{ wishlist_item.item_name }}({{wishlist_item.category_name}} / {{wishlist_item.sub_category_name}})</h5>
                                                            <h5 v-else class="w-100 mb-2">{{ wishlist_item.item_name }}</h5>
                                                            <h6 class="w-100 mb-2 text-black">
                                                                <span v-if="parseFloat(product.unit_quantity) != '0'">
                                                                    {{ Number( product.unit_quantity ).toFixed(1) }}
                                                                </span>
                                                                <span v-if="product.unit_name != null">
                                                                    {{ product.unit_name }}
                                                                </span>
                                                                <span v-if="product.packaging_name != null">
                                                                    {{ product.packaging_name }}
                                                                </span>
                                                            </h6>
                                                            
                                                            <h6 class="w-100 mb-2 text-black" v-if="parseFloat(product.min_qty) > 0">
                                                                (Min {{ product.min_qty }} - Max {{ product.max_qty }})
                                                            </h6>
                                                            
                                                            <h6 class="w-100 mb-2 text-black" v-if="product.choices != null">
                                                                <span v-for="(choice,ind) in product.choices" v-bind:key="ind">
                                                                    {{choice.choice_prices != null && choice.choice_prices.length > 0 ? choice.variant_choice_name:''}}:
                                                                    <span v-for="(choice_price,ind1) in choice.choice_prices" v-bind:key="ind1">
                                                                    <span v-if="(choice_price.is_added == 1)">
                                                                        {{choice_price.variant_sub_choice_name}}<template v-if="ind1 + 1 < choice.choice_prices.length">, </template>
                                                                    </span>
                                                                    </span>
                                                                    <br>
                                                                </span>
                                                            </h6>
                                                            <h6 class="w-100 mb-2 text-black" v-if="product.addons != null">
                                                                <span v-for="(addon,ind) in product.addons" v-bind:key="ind">
                                                                    {{addon.addon_prices != null && addon.addon_prices.length > 0 ? addon.variant_addon_name:''}}:
                                                                    <span v-for="(addon_price,ind1) in addon.addon_prices" v-bind:key="ind1">
                                                                    <span v-if="(addon_price.is_added == 1)">
                                                                        {{addon_price.variant_sub_addon_name}}<template v-if="ind1 + 1 < addon.addon_prices.length">, </template>
                                                                    </span>
                                                                    </span>
                                                                    <br>
                                                                </span>
                                                            </h6>
                                                            <h6 class="w-100 mb-2 text-black" v-if="wishlist_item.combo_variants_item!=null && wishlist_item.combo_variants">
                                                                {{getAddedWishlistComboItems(wishlist_item.combo_variants_item)}}
                                                                <span v-for="(wishlist_combo_variant,wishlist_combo_index) in getAddedWishlistComboItems(wishlist_item.combo_variants_item)" :key="wishlist_combo_index">
                                                                    {{wishlist_combo_variant}}
                                                                    <br>
                                                                </span>
                                                            </h6>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td class="unit-price valign-middle">
                                                    <span>
                                                        <h5 class="price">
                                                            <span v-html="priceFormatter(getUnitPrice(product))"></span>
                                                        </h5>
                                                    </span>
                                                </td>
                                                <td class="valign-middle quantity-main">
                                                    <span>
                                                        {{ wishlist_item.quantity }}
                                                    </span>
                                                </td>
                                                <td class="valign-middle close-main d-none d-md-table-cell">
                                                    <button
                                                    class="close"
                                                    @click="removeWishList(wishlist_item.item_id, wishlist_item.item_variants_id)"
                                                    >&times;</button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                                <div class="basket-payment col-lg-4 mt-4 mt-lg-0">
                                    <form class="wishlist-reminder-form available-option" method="post" @submit.prevent="saveWishlist">
                                    <ul class="payment-details-wrap list-unstyled">
                                        <li class="justify-content-between mt-3 pb-3 pb-md-0">
                                            <input 
                                                type="text" 
                                                name="wishlistname" 
                                                placeholder="Wishlist Name" 
                                                class="offer-input mb-0 form-control" 
                                                v-model="wishlist_name" 
                                                v-validate="'required'"
                                                :class="{error:errors.has('wishlistname')}">
                                            <span v-show="errors.has('wishlistname')" class="help is-danger">Enter Wishlist Name</span>
                                        </li>
                                        <li class="d-flex flex-wrap justify-content-between delivery-charge wishlist-reminder-box">
                                            <h5>Wishlist Reminder</h5>
                                            <span class="tax-and-charges cart-section" title="You will get a reminder thru notification alert for the Wish-list as per the settings here. Cart will not be created automatically from Wish-list and can bee manually created anytime."><b><img class="wishlist-info" src="../../assets/images/svgs/infoicon.svg"/></b></span>
                                            <div class="d-flex flex-wrap justify-content-between align-items-center w-100 wishlist-remainer">
                                                <div class="form-row">
                                                    <div class="d-flex flex-wrap radio-dt">
                                                        <input 
                                                            type="radio" 
                                                            id="daily" 
                                                            name="wishlist_reminder" 
                                                            value="1"
                                                            required
                                                            v-model="wishlist_reminder">
                                                            <label for="daily">Daily</label>
                                                    </div>
                                                </div>
                                                <div class="form-row">
                                                    <div class="d-flex flex-wrap radio-dt">
                                                        <input 
                                                            type="radio" 
                                                            id="weekly" 
                                                            name="wishlist_reminder" 
                                                            value="2" 
                                                            required
                                                            v-model="wishlist_reminder">
                                                            <label for="weekly">Weekly</label>
                                                        </div>
                                                </div>
                                                <div class="form-row">
                                                    <div class="d-flex flex-wrap radio-dt">
                                                        <input 
                                                            type="radio" 
                                                            id="monthly" 
                                                            name="wishlist_reminder" 
                                                            required
                                                            value="3" 
                                                            v-model="wishlist_reminder">
                                                            <label for="monthly">Monthly</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <div class="date-timepicker-main d-flex flex-wrap" v-if="wishlist_reminder == 1">
                                            <div class="form-group date-timepicker scheduled-time w-100">
                                                <input 
                                                    type="text" 
                                                    name="scheduled_time" 
                                                    id="scheduled_time"
                                                    disabled
                                                    class=" w-100 form-control" 
                                                    v-model="scheduled_wishlist_daily">
                                            </div>
                                        </div>

                                        <div v-if="wishlist_reminder == 2">
                                            <select v-model="scheduled_wishlist_weekly" class="w-100 mb-3 form-control">
                                                <option value="">Select Day of Week</option>
                                                <option
                                                v-for="(scheduled_wishlist_weekly,index) in weekofday" :key="index"
                                                :value="scheduled_wishlist_weekly.value"
                                                > {{ scheduled_wishlist_weekly.day }}</option>
                                            </select>
                                        </div>
                                        <div v-if="wishlist_reminder == 3">
                                            <select v-model="scheduled_wishlist_monthly" class="w-100 mb-3 form-control">
                                                <option value="">Select Day of Month</option>
                                                <option>Monthly on day {{ getdate }}</option>
                                                <option>Monthly on the {{week}}</option>
                                            </select>
                                        </div>
                                        <li class="button row mx-0 justify-content-center">
                                            <button :style="colorObj" class="btn button-style check-out hoverEffect" :disabled="loading">Create Wishlist</button>
                                            <router-link :to="'/profile#wishlist'" class="text-danger mt-2">Cancel</router-link>
                                        </li>
                                    </ul>
                                    </form>
                                </div>
                            
                        </div>
                    </div>
                </div>
            
        </section>
        <div
            class="modal fade"
            id="WishlistStoreModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="WishlistStoreModalLabel"
            aria-hidden="true"
        >
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <div class="modal-body">
                        <selectwishliststore></selectwishliststore>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="modal fade wishlist-store-item"
            id="WishlistStoreItemModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="WishlistStoreItemModalLabel"
            aria-hidden="true"
        >
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <div class="modal-body">
                        <h2 class="page-heading text-center">ADD ITEM TO WISHLIST</h2>
                        <selectwishlistitem ref="store_wishlist_item" v-if="category.store_id!=0" :storeid="category.store_id"></selectwishlistitem>
                    </div>
                </div>
            </div>
        </div>
        <div class="container" v-if="loading==true">
            <p class="text-center">
                <img src="../../assets/images/loading.gif" />
            </p>
        </div>
    </Defaultlayout>
</template>

<script>
import Defaultlayout from "../../layouts/Defaultlayout";
import moment from 'moment';
import helper from '../../store/helper';
import SelectWishlistStore from "../wishlist/SelectWishlistStore";
import SelectWishlistItem from "../wishlist/SelectWishlistItem";
import { bus } from "../../main";
import Avatar from "vue-avatar-component";
export default {
    name:"Addwishlist",
    components:{
        Defaultlayout,
        Avatar,
        "selectwishliststore": SelectWishlistStore,
        "selectwishlistitem": SelectWishlistItem,
    },
    mixins:[helper],
    data(){
        return {
            userprofile:null,
            loading:true,
            wishlist_reminder: 1,
            scheduled_wishlist_daily:"",
            scheduled_wishlist_weekly:"",
            scheduled_wishlist_monthly:"",
            wishlist_name:"",
            date: "",
            currentOffset:"",
            istoffset: 330,
            isttime:"",
            getdate:"",
            adjustdate:"",
            prefixes:"",
            week:"",
            currentweekdate:"",
            weekofday:[
                {
                    "day": "Every Monday",
                    "value": 0
                },
                {
                    "day": "Every Tuesday",
                    "value": 1
                },
                {
                    "day": "Every Wednesday",
                    "value": 2
                },
                {
                    "day": "Every Thursday",
                    "value": 3
                },
                {
                    "day": "Every Friday",
                    "value": 4
                },
                {
                    "day": "Every Saturday",
                    "value": 5
                },
                {
                    "day": "Every Sunday",
                    "value": 6
                },
            ],
            wishlist:{
                user_id:"",
                store_id:"",
                wishlist_name:"",
                reminder_type:"",
                dayofweek:"",
                items:"",
                daily_time:"",
                monthly_date:""
            },
            wishliststoredetails: null,
            avatarBackgroundColor:"",
            avatarTextColor:"",
            getcategorylist:null,
            category:{
                store_id:0
            },
            wishlist_store_item:null,
            wishlist_store_items:[],
            user_wishlist_item:[],
            duplicate_wishlist:{
                wishlist_id:""
            },
            is_business_model:0,
            userwishlist: {
                user_id:"",
                page:1
            },
            enablesavewishlist:false,
            show_price_same_font:1,
            currency_symbol: "₹"
        }
    }, 
    computed:{
        totalWishListQty(){
            var totalqty = 0;
            if(this.wishlist_store_item!=null){
                for(let i=0;i<this.wishlist_store_item.length;i++){
                    totalqty+= parseFloat(this.wishlist_store_item[i].quantity);
                }
            }
            return totalqty;
        }
    },
    mounted(){
        if (localStorage.getItem("user") != null) {
            this.userprofile = JSON.parse(localStorage.getItem("user"));
        } else {
            this.userprofile = null;
            window.location.href="/";
        }
        this.is_business_model = localStorage.getItem("is_business_model");
        if(this.is_business_model == 3){
            bus.$on("citi_wise_dashboard", response => {
                var data = JSON.parse(JSON.stringify(response.data));
                data = data.customer_stores[0];
                if(Object.keys(data).length!=0){
                    this.wishliststoredetails = data;
                    this.category.store_id = this.wishliststoredetails.store_id;
                }
            }); 
        }
        if(this.$route.path.includes("add-wishlist")){
            document.body.classList.add('wishlist-top-bar');
        }
        bus.$on("wishlist-store-obj", data => {
            if(Object.keys(data).length!=0){
                this.wishliststoredetails = data;
            }
        });
        bus.$on("add-to-wishlist", data => {
            this.wishlist_store_item = data;
            this.wishlist_store_item.forEach(item => {
                var obj = {};
                obj["item_id"] = item.item_id;
                obj["item_variants_id"] = item.item_variants_id;
                obj["quantity"] = item.quantity;
                if(item.is_combo == 1){
                    obj["combo_variants"] = item.combo_variants;
                }
                this.user_wishlist_item.push(obj);
            });
        });
        this.loading = true;
        if(localStorage.getItem("duplicate_wishlist")!=null){
            var _this = this;
            this.duplicate_wishlist.wishlist_id = this.$route.params.wishlist_id;
            this.$store.dispatch("customerwishlistdetails", this.duplicate_wishlist).then(response => {
                if(response.status == 1){
                    var data = response.data;
                    var storeObj = {};
                    storeObj["storename"] = data.store_name;
                    storeObj["storelogo"] = data.store_logo;
                    storeObj["areaname"] = data.area_name + ", " +data.city_name + ", "+data.state_name + ", "+data.zipcode;
                    _this.wishliststoredetails = storeObj;
                    _this.wishlist_store_item = data.items;
                    var storeItemObj = {};
                    data.items.forEach(variant => {
                        storeItemObj["item_id"] = variant.item_id;
                        storeItemObj["item_variants_id"] = variant.item_variants_id;
                        storeItemObj["quantity"] = variant.quantity;
                        _this.user_wishlist_item.push(storeItemObj);    
                    });
                    _this.wishlist_store_items = JSON.stringify(this.user_wishlist_item);
                    _this.category.store_id = data.store_id;         
                }
                this.loading = false;
            });
        }
        this.originalColor();
        this.date = new Date();
        this.currentOffset = this.date.getTimezoneOffset();
        this.getdate = this.date.getDate();
        this.isttime = new Date(this.date.getTime() + (this.istoffset + this.currentOffset)*60000);
        this.scheduled_wishlist_daily = this.scheduled_wishlist_daily!='' ? ('0' + this.isttime.getHours()).slice(-2) + ':' + ('0' + (this.isttime.getMinutes())).slice(-2) : "08:00";
        this.getWeekOfMonth(this.date);
        var month_week = this.week;	
        var momnetdate = moment(this.date);
        var week_day = momnetdate.day();
        this.getDaysofMonth(month_week,week_day);
        this.removeLocalWishList();
        if(localStorage.getItem("duplicate_wishlist") == null){
          this.loading = false;  
        }
        this.currency_symbol = localStorage.getItem("currency_symbol") != null && localStorage.getItem("currency_symbol") != "null"?localStorage.getItem("currency_symbol"):"₹";
        bus.$on("globalsetting",data => {
            if(Object.keys(data).length != 0){
                this.show_price_same_font = JSON.parse(data).show_price_same_font;
            }
        });
    },
    updated(){
        window.$('.wishlist-add.ordered-profile a').css({ color: this.buttonColor.backgroundColor});
        window.$('.wishlist-add.ordered-profile').css({ borderColor: this.buttonColor.backgroundColor});
        window.$('.wishlistadd-icon svg rect').css({ stroke: this.buttonColor.backgroundColor});
        this.avatarBackgroundColor = this.cancelReOrderButton.backgroundColor;
        this.avatarTextColor = localStorage.getItem("css_button_customer_hover_font");
        window.$('.avatar table tbody tr td').css({ color: this.avatarTextColor});
    },
    methods:{
        priceFormatter(value) {
            if(value){
                var price = value.toString().split(".")[0];
                var decimal = value.toString().split(".")[1];
                var response = this.currency_symbol + price;
                var decimal_font_size = this.show_price_same_font;
                if(decimal != undefined){
                    if(decimal_font_size == 1){
                        response += "<span>." + decimal + "</span>";
                    } else {
                        response += "<span class='price-decimal'><small>." + decimal + "</small></span>";
                    }
                } else {
                    if(decimal_font_size == 1){
                        response += "<span>.00</span>";
                    } else{
                        response += "<span class='price-decimal'><small>.00</small></span>";
                    }
                }
                return response;
            } else {
                return this.currency_symbol + '0';
            }
        },
        getUnitPrice(item){
            var sum = 0;
            sum += parseFloat(item.discounted_price);
            if(item.choices != null) {
                item.choices.forEach(choice => {
                choice.choice_prices.forEach(choice_price => {
                    if(choice_price.is_added == 1){
                        sum += parseFloat(choice_price.price);
                    }
                });
                });
            }

            if(item.addons != null) {
                item.addons.forEach(addon => {
                addon.addon_prices.forEach(addon_price => {
                    if(addon_price.is_added == 1){
                        sum += parseFloat(addon_price.price);
                    }
                });
                });
            }
            return parseFloat(sum).toFixed(2);
        },
        closeWishlistModal(){
            window.location.href = "/add-wishlist";
            window.location.reload();
        },
        getAddedWishlistItems(items){
            return items.filter(
                function(elem) {
                if (elem.is_added == "1") return elem;
                }
            );
        },
        getAddedWishlistComboItems(items){ 
            return items.filter(data => {
                data.combo_variants.length > 0
            });
            
        },
        removeWishList(item_id,item_variant_id){
            var user_wishlist = JSON.parse(localStorage.getItem("user_wishlist")) || [];
            let item_index = user_wishlist.findIndex(e => e.item_id == item_id);
            if(item_index > -1){
                let item_variant_index = user_wishlist[item_index].item_variants.findIndex(e => e.item_variants_id == item_variant_id);
                if (item_variant_index > -1){
                    this.wishlist_store_item[item_index].item_variants[item_variant_index].is_added = 0;
                    this.wishlist_store_item[item_index].item_variants[item_variant_index].quantity = 1;
                }
                this.wishlist_store_item.splice(item_index,1);
                return this.wishlist_store_item;
            }
        },
        removeLocalWishList(){
            localStorage.removeItem("user_wishlist");
        },
        goBack(){
            this.$router.go(-1);
        },
        getWeekOfMonth(date) {
            var days = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];
            this.prefixes = ['first', 'second', 'third', 'fourth', 'fifth'];
            this.week = this.prefixes[Math.floor(date.getDate() / 7)] + ' ' + days[date.getDay()];
            return this.week;

        },
        getDaysofMonth(week,dayofweek){
            var weekdate = moment(this.date).startOf("month").day(dayofweek);
            this.currentweekdate = weekdate.add(7 * (week - 1), "days");
            this.currentweekdate = moment(this.currentweekdate).format('dddd');
        },
        openStore(){
            this.wishlist_store_item = null;
            window.$('#WishlistStoreModal').modal('show');
        },
        selectStoreItem(storeid){
            this.category.store_id = storeid;
            window.$('#WishlistStoreItemModal').modal('show');
        },
        saveWishlist(){
            //this.loading = true;
            this.enablesavewishlist = true;
            this.$validator.validateAll().then(result => {
                if(result){
                    this.wishlist.user_id = this.userprofile!=null ? this.userprofile.user_id : "0";
                    this.wishlist.store_id = this.wishliststoredetails!=null ? this.wishliststoredetails.storeid : "0";
                    this.wishlist.items = JSON.stringify(this.user_wishlist_item);
                    this.wishlist.wishlist_name = this.wishlist_name;
                    this.wishlist.reminder_type = this.wishlist_reminder;
                    if(this.wishlist.reminder_type == 1){
                        this.wishlist.dayofweek = 0;
                        this.wishlist.daily_time = this.scheduled_wishlist_daily;
                        this.wishlist.monthly_date = "";
                    }
                    if(this.wishlist.reminder_type == 2){
                        if(this.scheduled_wishlist_weekly!=''){
                            this.wishlist.dayofweek = this.scheduled_wishlist_weekly;
                        } else{
                            this.$toast.error("Select Day of Week");
                            return false;
                        }
                        
                        this.wishlist.daily_time = "00:00";
                        this.wishlist.monthly_date = "";
                    }
                    if(this.wishlist.reminder_type == 3){
                        this.wishlist.dayofweek = 0;
                        this.wishlist.daily_time = "00:00";
                        if(this.scheduled_wishlist_monthly!=''){
                            this.wishlist.monthly_date = ('0' + this.scheduled_wishlist_monthly.replace(/[^0-9]/g, '')).slice(-2) + '-' + ('0' + (this.date.getMonth()+1)).slice(-2);
                        } else {
                            this.$toast.error("Select Day of Month");
                            return false;
                        }
                    }
                    this.uniqueWishListName(this.wishlist.wishlist_name, this.wishlist.user_id);
                    this.$store
                        .dispatch("customermanagewishlist", this.wishlist)
                        .then(response => {
                            if(response.status == 0){
                                if(this.wishlist.store_id == 0){
                                    this.$toast.error("Select a store for Wish-list to be created");
                                    return false;
                                }
                                if(this.wishlist.items == ''){
                                    this.$toast.error("Add minimum one item from store to create a Wish-list");
                                    return false;
                                }
                            } else {
                                setTimeout(() => { this.enablesavewishlist = false}, 3000);
                                this.$router.push('/profile#wishlist');
                                this.$toast.success(response.message);
                            }
                        });
                }
            })
        },
        redirectToStore(){
            this.$router.push('search');
        },
        uniqueWishListName(wishlist_name, id){
            this.userwishlist.user_id = id;
            this.userwishlist.page = 1;
            this.$store.dispatch("customerwishlist", this.userwishlist).then(response => {
                this.api_response_status = response.status;
                this.userwishlistdetails = this.api_response_status == 1 ? response : response;
                this.userwishlistdetails.data.forEach(name =>{
                    if(name.wishlist_name == wishlist_name){
                        this.$toast.error("Enter a unique Wish-list Name");
                        return false;
                    }
                })
            })
            
        }
    }
}
</script>